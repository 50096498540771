<template>
  <div>
    <v-card class="mx-auto">
      <v-app-bar dark color="primary">
        <v-icon large class="mr-3">assignment_turned_in</v-icon>
        <v-toolbar-title>Task Status</v-toolbar-title>
      </v-app-bar>
    </v-card>
    <v-row>
      <v-col>
        <v-select
          label="Select Activity"
          :items="tasks"
          item-text="name.en"
          item-value="_id"
          outlined
          dense
          class="mt-3"
          v-model="dataToSend.taskId"
        />
      </v-col>
      <v-col>
        <v-select
          label="Select Status"
          :items="[
            { key: 'A', label: 'Active' },
            { key: 'C', label: 'Complete' },
          ]"
          item-text="label"
          item-value="key"
          outlined
          dense
          multiple
          class="mt-3"
          v-model="dataToSend.status"
          :disabled="!dataToSend.taskId"
        />
      </v-col>
      <v-col>
        <v-btn color="primary" class="mt-3" @click="getAssignedTasks">Get Assigned Tasks</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search tasks"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      v-if="assignedTasks.length > 0"
      :headers="headers"
      :items="assignedTasks"
      :search="search"
    />
  </div>
</template>


<script>
import RestResource from "../../../services/dataServiceMobileV2";

const service = new RestResource();

export default {
  data() {
    return {
      tasks: [],
      dataToSend: {
        taskId: undefined,
        status: undefined,
      },
      headers: [
        { text: "User Id", value: "userId", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Task Type", value: "taskType", sortable: true },
      ],
      assignedTasks: [],
      search: '',
    };
  },

  mounted() {
    this.getTasks();
  },

  methods: {
    getTasks() {
      service.getTasks().then((r) => {
        this.tasks = r.data;
      });
    },

    getAssignedTasks() {
      service.getAssignedTasks(this.dataToSend).then((r) => {
        this.assignedTasks = r.data;
      });
    },
  },
};
</script>
